.questions-info-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  // margin-top: 70px;
  height: 600px;

  .title-number-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    position: absolute;
    min-height: 600px;
    left: 50%;
    transform: translate(-50%, -15%);
    top: 13%;

    .default-text {
      display: flex;
      flex-direction: column;
      // font-size: 25px;
      color: white;
      max-width: 500px;
      margin: 0;
      font-size: 18px;
    }

    .accept-next-btn {
      .right-arrow {
        color: #ff6500;
        font-size: 35px;
      }

      button {
        font-weight: bold;
        font-size: 20px;
        font-family: Roboto;
        border-radius: 45px;
        padding: 15px 45px 15px 75px;
        color: #d1d1d1;
        border: solid 2px #d1d1d1;
        margin-left: -20px;
        background: linear-gradient(to bottom, #464646, #242424);
        border: none !important;
      }
    }

    .go-to-question-btn {
      margin-left: 40px;
    }

    .title-number-data {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .title,
      .number,
      .text {
        color: #a7a7a7;
      }
      .title {
        font-size: 60px;
      }
      .number {
        font-size: 25px;
        display: flex;
        justify-content: center;
      }

      .text {
        width: 500px;
        text-align: left;
        margin: 40px auto 0;
        font-size: 18px;
      }
    }
  }
  .questions-list {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    // padding-left: 40px;
    padding-left: 15px;
    // height: 470px;
    // min-width: 400px;
    min-width: 340px;
    z-index: 3;

    .questions-names-container {
      width: 100%;
      // border-right: solid 1px #707070;
      .cur-question {
        color: #ff6500;
        margin-bottom: 7px;
        list-style: none;
        width: 100%;
        position: relative;
        font-size: 25px;
        .question-icon {
          color: #ff6500;
          position: absolute;
          left: -30px;
          // bottom: .1px;
          font-size: 30px;
          top: 4px;
        }
      }
      .question {
        padding-right: 10px;
        color: #a7a7a7;
        list-style: none;
        cursor: pointer;
        margin-bottom: 7px;
        font-size: 22px;
      }
      .done {
        color: #3636ff;
      }
    }
    // .line {
    //   width: 1px;
    //   height: 100%;
    //   border-left: solid 1px #707070;
    // }
  }
}

@media (max-width: 1220px) {
  .questions-info-container {
    .title-number-container {
      // display: flex;
      // justify-content: space-between;
      // flex-direction: column;
      // align-items: center;
      // min-height: unset;

      display: flex;
      // justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      min-height: unset;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;

      .default-text {
        margin-top: 70px;
      }

      .title-number-data {
        margin-bottom: 40px;
        .title {
          font-size: 40px;
        }
        .number {
          font-size: 18px;
        }
      }
    }
    .questions-list {
      padding-left: 20px;

      .questions-names-container {
        .cur-question {
          font-size: 20px;
        }
        .question {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .questions-info-container {
    // .title-number-container {
    //   // display: flex;
    //   // justify-content: space-between;
    //   // flex-direction: column;
    //   // align-items: center;
    //   // min-height: unset;

    //   display: flex;
    //   justify-content: space-between;
    //   flex-direction: column;
    //   align-items: center;
    //   min-height: unset;
    //   justify-content: center;
    //   position: relative;
    //   top: unset;
    //   left: unset;
    //   transform: unset;

    //   .title-number-data {
    //     .title {
    //       font-size: 40px;
    //     }
    //     .number {
    //       font-size: 18px;
    //     }
    //   }
    // }
    .questions-list {
      padding-left: 20px;

      .questions-names-container {
        .cur-question {
          font-size: 20px;
        }
        .question {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .questions-info-container {
    height: unset;
    // .title-number-container {
    //   display: flex;
    //   justify-content: space-between;
    //   flex-direction: column;
    //   align-items: center;
    //   min-height: unset;
    //   justify-content: center;
    //   position: relative;
    //   top: unset;
    //   left: unset;
    //   transform: unset;
    // }
    .questions-list {
      height: 370px;
      width: 370px;
    }
  }
}

@media (max-width: 600px) {
  .questions-info-container {
    .title-number-container {
      .title-number-data {
        .title {
          font-size: 25px;
        }
        .number {
          font-size: 14px;
        }
      }
    }

    .questions-list {
      padding-left: 10px;
      .questions-names-container {
        padding-left: 20px;
        .question {
          font-size: 14px;
        }
        .cur-question {
          font-size: 16px;
          .question-icon {
            left: -20px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .questions-info-container {
    .title-number-container {
      .title-number-data {
        .title {
          font-size: 20px;
        }
        .number {
          font-size: 12px;
        }
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  //Question list
  // .questions-info-container {
  //   margin-top: 0 !important;
  //   height: unset !important;
  //   .questions-list {
  //     padding-left: 32px !important;
  //     .questions-names-container {
  //       .question {
  //         margin-bottom: 5px !important;
  //         font-size: 18px !important;
  //       }
  //       .cur-question {
  //         font-size: 20px !important;
  //       }
  //     }
  //   }
  //   .title-number-container {
  //     min-height: 480px !important;
  //     .title-number-data {
  //       .title {
  //         font-size: 40px !important;
  //       }
  //       .number {
  //         font-size: 18px !important;
  //       }
  //     }
  //   }
  // }

  .questions-info-container {
    zoom: 0.8;
  }
}
