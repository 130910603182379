.customTableStyle {
  border-radius: 4px !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
  .rdt_TableCell > div {
    white-space: unset !important;
  }
  .rdt_TableBody {
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      color: gray;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 12px;
      width: 10px;
      border: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #3b3b3b;
    }
  }
}

.improvement-container {
  padding: 0 94px;
}

.audit-copyright {
  
  width:100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  right: 30px;
  margin-bottom: auto;
  // bottom: -50px;
  /* margin-right: 40px; */
  /* margin-left: 3px; */
  font-size: 12px;
  color: white;
  p {
    margin-bottom: 0px;
  }
}

.fixedEllipsis {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: normal !important;
  max-width: inherit;
  p {
    width: 100%;
    font-size: 14px;
    @media (max-width: 1250px) {
      font-size: 13px;
    }
    @media (max-width: 1180px) {
      font-size: 12px;
    }
  }
}
