.confirm-finish-rm-modal {
  .MuiDialog-paperWidthSm {
    width: 715px;
    min-height: 400px;
  }
  .input-container {
    width: 100%;
    text-align: center;
    .input {
      outline: none;
      border: 0px;
      margin: auto;
      border-radius: 10px;
      background-color: #cecece;
      width: 100%;
      padding: 0 20px;
      height: 50px;
    }
  }

  .MuiDialogContent-root {
    align-items: center;
    justify-content: center;
    display: flex;
    flex: unset;
  }

  .error-section {
    width: 100%;
    .error {
      margin: 10px 24.5px 0;
      // padding: 8.3px 86.5px 8.3px 19.5px;
      border-radius: 10px;
      background-color: #cf0000;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      span:first-of-type {
        border-right: 1px solid white;
        padding-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      span {
        padding: 0 15px;
      }

      .warning-icon {
        margin-right: 12px;
        font-size: 20px;
      }
    }
  }
  .MuiDialog-paperScrollPaper {
    background-image: url("../../../../images/audit-bg.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #8d8d8d;
    justify-content: space-around;

    .MuiDialogTitle-root {
      text-align: center;
      color: #a7a7a7;
      .MuiTypography-h6 {
        font-size: 24px;
      }
    }

    // min-width: 715px;
    // height: 300px;

    .MuiDialogActions-root {
      justify-content: center;
      .ok-btn, .cancel-btn {
        background-color: #ff872b;
        color: white;
        width: 130px;
        height: 40px;
        font-size: 20px;
    
      }
    }

    

    .close-icon {
      position: absolute;
      top: 3px;
      right: 15px;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
      color: #a7a7a7;
      transition: all 0.3s ease-in-out;

      &:hover {
        // transition: all .3 ease-in-out;
        color: red;
      }
    }

  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .confirm-finish-rm-modal {
    zoom: 0.8;
  }
}
