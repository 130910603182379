// Next Button
.custom-next-btn {
  text-align: center;
  button {
    font-weight: normal;
    color: hsl(0, 0%, 82%);
    height: 50px;
    line-height: 54px;
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
    background: linear-gradient(to bottom, #464646, #242424);
    font-size: 16px;
    letter-spacing: 1px;
    border: none;

    span p {
      margin: 0 !important;
      font-size: unset !important;
    }

    &:hover {
      color: #ff872b;
    }

    .MuiButton-label::after {
      position: relative;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-right: 2px solid #ff6500;
      border-top: 2px solid #ff6500;
      transform: rotate(45deg);
      margin-left: 10px;
    }
  }
}

// Accounts Button
.account-btn-container {
  .accounts-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    border-radius: 45px;
    background: linear-gradient(to bottom, #464646, #242424);
    font-size: 19.2px;
    // font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 8px;
    height: 55px;
    // height: 90px;

    span {
      color: #fff !important;
      margin: 0;
      p {
        margin: 0;
      }
    }

    .MuiButton-label {
      width: fit-content;
      input {
        height: 100%;
      }
    }

    &::after {
      position: relative;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-right: 2px solid #ff6500;
      border-top: 2px solid #ff6500;
      transform: rotate(45deg);
      margin-left: 10px;
    }

    &:hover {
      color: #fff;
      border: none;
      background-color: #ff6500;
      background-image: linear-gradient(to bottom, #ff4600, #802300);
    }
  }
}

//Back buttons
.back-btn-container {
  .back-btn {
    font-weight: normal;
    color: #d1d1d1;
    // height: 54px;
    // height: 74px;
    height: 50px;
    line-height: 54px;
    // width: 180px;
    // width: 242px;
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
    background: linear-gradient(to bottom, #464646, #242424);
    // font-size: 19.2px;
    // font-size: 20px;
    font-size: 16px;
    letter-spacing: 1px;
    // margin-bottom: 30px;
    // margin-top: 30px;
    // padding: 8px;
    p {
      margin: 0;
    }

    span {
      width: fit-content;
    }

    &:hover {
      span {
        color: #ff872b;
      }
    }

    .MuiButton-label::before {
      // content: "\276E";
      // color: #ff6500;
      // left: 26px;
      // position: absolute;
      position: relative;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-bottom: 2px solid #ff6500;
      border-left: 2px solid #ff6500;
      transform: rotate(45deg);
      margin-right: 10px;
    }

    .MuiButton-label {
      width: fit-content;
      input {
        height: 100%;
      }
    }
  }
}

/// exit button

//Back buttons
.exit-btn-container {
  width: 100%;
  padding-left: 20px;
  position: absolute;
  top: 90px;
  z-index: 3;
  .back-btn {
    font-weight: bold;
    color: #d1d1d1;
    height: 40px;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
    background: linear-gradient(to bottom, #464646, #242424);

    font-size: 12px;
    letter-spacing: 1px;
    p {
      margin: 0;
    }

    span {
      width: fit-content;
    }

    &:hover {
      span {
        color: #ff872b;
      }
    }

    .MuiButton-label::before {
      position: relative;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-bottom: 2px solid #ff6500;
      border-left: 2px solid #ff6500;
      transform: rotate(45deg);
      margin-right: 10px;
    }

    .MuiButton-label {
      width: fit-content;
      input {
        height: 100%;
      }
    }
  }
}
