.info-modal-container {
  display: flex;
  flex-direction: column;
  .info-modal-content {
    max-width: 700px;
    margin: auto;
    text-align: center;

    .img-text-content {
      position: relative;
      height: 100%;
      text-align: center;
    }
  }

  .dialog-content {
    max-width: 1000px;
    .MuiPaper-root {
      background-color: unset !important;
      background-image: none !important;
    }
    .img-text-content {
      img {
        display: none;
      }
    }
  }

  .right-line,
  .left-line {
    position: absolute;
  }

  .right-line {
    right: 0;
    top: 0;
    height: 100%;
  }
  .left-line {
    left: 0;
    top: 0;
    height: 100%;
  }
  .MuiDialog-paperWidthSm {
    min-width: 1100px;
    min-height: 700px;
    border: 1px solid #8d8d8d;
  }

  .MuiDialogContent-root {
    padding: 8px 55px;
    color: white;
    text-align: left;

    .MuiTypography-colorTextSecondary {
      color: white;
    }
  }
  .MuiDialog-paperScrollPaper {
    background-image: url("../../../../images/audit-bg.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: space-around;

    .MuiDialogTitle-root {
      text-align: center;
      color: #ff6500;
      padding-top: 0;

      .MuiTypography-h6 {
        font-size: 30px;
      }
    }

    .MuiDialogActions-root {
      justify-content: center;
      .ok-btn {
        background-color: #ff872b;
        color: white;
        width: 130px;
        height: 40px;
        font-size: 20px;
        margin-bottom: -25px;
      }
    }

    .close-icon {
      position: absolute;
      top: 3px;
      right: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #a7a7a7;
      transition: all 0.3s ease-in-out;

      &::after {
        content: "\d7";
        font-size: 32px;
        transform: rotate(0deg);
        color: #ff6500;
        transition: all 0.3s ease-in-out;
      }

      &:hover::after {
        color: red;
      }
    }
  }
}

/// the Modified info modal

.modified-info-modal-container {
  display: flex;
  flex-direction: column;

  .info-modal-content {
    max-width: 700px;
    margin: auto;
    text-align: center;
    // padding-top: 100px !important;
    // background-color: red;
    // h2 {
    //   margin-top: 150px;

    // }
    .img-text-content {
      position: relative;
      height: 100%;
      text-align: center;
    }
  }

  .right-line,
  .left-line {
    position: absolute;
  }

  .right-line {
    right: 0;
    top: 0;
    height: 100%;
  }
  .left-line {
    left: 0;
    top: 0;
    height: 100%;
  }
  .MuiDialog-paperWidthSm {
    min-width: 77%;
    // min-height: 400px;
    min-height: 40%;
    border: 1px solid #8d8d8d;
    position: absolute;
    top: 25%;
    left: 10%;
    transform: translate(-10%, -14%);
  }

  .MuiDialogContent-root {
    padding: 8px 55px;
    color: white;
    text-align: left;

    .MuiTypography-colorTextSecondary {
      color: white;
    }
  }
  .MuiDialog-paperScrollPaper {
    background-image: url("../../../../images/audit-bg.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: space-around;

    .MuiDialogTitle-root {
      text-align: center;
      color: #ff6500;
      padding-top: 0;

      .MuiTypography-h6 {
        font-size: 30px;
      }
    }

    .MuiDialogActions-root {
      justify-content: center;
      .ok-btn {
        background-color: #ff872b;
        color: white;
        width: 130px;
        height: 40px;
        font-size: 20px;
        margin-bottom: -25px;
      }
    }

    .close-icon {
      position: absolute;
      top: 3px;
      right: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #a7a7a7;
      transition: all 0.3s ease-in-out;

      &::after {
        content: "\d7";
        font-size: 32px;
        transform: rotate(0deg);
        color: #ff6500;
        transition: all 0.3s ease-in-out;
      }

      &:hover::after {
        color: red;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .info-modal-container {
    zoom: 0.8;
  }

  .modified-info-modal-container {
    zoom: 0.8;
  }
}
