.layout {
  min-height: 100vh;
  // font-family: "KoHo", sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
//   padding-bottom: 15px;
  // margin-left: 15px;
  // margin-right: 15px;
}
@font-face {
  font-family: "Segoe UI Regular", "Sans-serif";
  src: local("GoldmanBold"),
    url("../../font/segoe-ui-4-cufonfonts-webfont/Segoe_UI.woff")
      format("truetype");
}

.font {
  font-family: "Segoe UI Regular", "Sans-serif";
}

// @media (-webkit-device-pixel-ratio: 1.25) {
//   .layout {
//     zoom: 0.8;
//   }
// }
