.page1__container {
  display: flex;
  height: 80vh;
  width: 95%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;

  .top__text {
    width: 80%;
    flex-grow: 4;
    margin-bottom: 0;
  }
  .middle__text {
    flex-grow: 3;
  }
  .down__text {
    width: 80%;
    flex-grow: 3;
    margin: 0;
  }
  .btn__holder {
    width: 75%;
    display: flex;
    justify-content: flex-end;
  }
}


@media (-webkit-device-pixel-ratio: 1.25) {
    .page1__container{
      zoom: 0.8;
    }
  }