.home-container {
  display: flex;
  // height: 100vh;
  justify-content: space-around;
  flex-direction: column;
  .archive-container {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    h3 {
      color: #dbdbdb;
      text-align: center;
    }

    .archive-supervision,
    .archive-roadmaps {
      min-height: 400px;
      width: 40%;
      .img-line {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;
      }

      ul {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow: auto;

        &::-webkit-scrollbar {
          color: gray;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 12px;
          width: 10px;
          border: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        .sv-content,
        .rm-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          opacity: 0.7;
          margin-bottom: 12px;
          font-size: 20px;

          .delete-btn {
            color: white;
            font-size: 30px;
            cursor: pointer;
            opacity: 0.7;

            :hover {
              transition: all 0.3s;
              opacity: 1;
              color: red;
            }
          }
          .sv-text,
          .rm-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: auto;
            cursor: pointer;
            .item_name {
              width: 60%;
            }
            .item_date_info_delete_container {
              width: 40%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .item_date {
                width: 90%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }

          .info-icon {
            color: #707070;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .btn-create-supervision {
    button {
      width: unset;
      padding: 6px 20px;
      margin: auto;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .home-container {
    zoom: 0.8;
  }
}
