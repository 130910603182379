.page2__container {
  width: 95%;
  margin: 0 auto;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: white;
  .top__text {
    width: 80%;
    // height: 25vh;
    margin: 0;
  }
  .middle__container {
    // height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .bar__conatiner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .the__bar {
        width: 100%;
        height: 80%;
      }
      .bar-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 20%;
        width: 95%;
        margin-left: 4%;
        color: white;
        margin-bottom: 6px;
        div {
          font-size: 12px;
          padding: 5px;
          text-align: center;
          max-width: 56px;
        }
      }
      .spcific-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 97%;
        margin-left: 3.5%;
        height: 10%;
      }
    }
  }
  .down__text {
    width: 80%;
    // height: 20vh;
    // margin-top: 80px;
    margin-top: 20px;
  }
  .btn__holder {
    width: 75%;
    display: flex;
    justify-content: space-between;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .page2__container {
    zoom: 0.8;
  }
}
