.improvement-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .pagination-container {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 82%;
    .pagenation {
      display: flex;
      color: white;
      justify-content: space-around;
      align-items: center;
      svg {
        font-size: 35px;
      }
      .content {
        display: flex;
        // margin-right: 16px;

        p {
          margin: 0;
        }
        .page-num {
          margin-right: 6px;
          // margin-left: 30px;
          color: #ed6c02;
          font-size: 20px;
          font-weight: bold;
        }
        .total-page-num {
          margin-left: 6px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .title-container {
    color: white;
    font-size: 25px;
    p {
      margin: 0;
    }
    margin-bottom: 12px;
  }
  .validated {
    color: #505050;
  }
  .question-name {
    display: flex;
    flex-direction: column;
  }
  .question-text {
    //  max-height: 90px;
    //  overflow: scroll;
    margin-bottom: 8px;
    margin-top: 10px;
  }
  .btns-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    .pdf-btn {
      text-align: right;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      color: white;
      width: 130px;
      align-items: center;
      margin: auto;
      cursor: pointer;

      img {
        height: 59px;
        width: 42px;
      }
      span {
        font-size: 12px;
        transition: all 0.3s ease-in-out;
        p {
          margin: 0;
        }
      }

      &:hover {
        span {
          color: rgb(209, 209, 209);
        }
      }
    }
  }
  .grid_cell {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    min-height: 65px;
    margin-top: 10px;
    button {
      margin-top: -7px;
      font-weight: 400;
    }

    span {
      padding: 0;
    }
  }
  .number {
    align-items: flex-start;
  }
  .grid-container-header {
    color: white;
  }
  .grid-container {
    color: white;
    height: 50vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .grid-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .go-to-question {
      font-size: 10px;
      max-width: 200px;
      height: 45px;
    }
  }

  .grid-title {
    width: 100%;
    margin: 0 0 20px 0 !important;
    background: #333333ab;
    padding: 10px;
    border-radius: 4px;
    p {
      margin: 0;
    }
    div {
      padding-top: 0 !important;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .improvement-container {
    zoom: 0.8;
  }
}
