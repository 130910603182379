.tut1__container {
  width: 100%;
  // height: 100%;
  height: 80vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;

  .title {
    // margin-bottom: 50px;
    
  }
  .risk-precentage {
    // margin-bottom: 50px;
    // margin-top: 30px;
    display: flex;
    font-size: 30px;
    
    span{
      color: white;
      margin-right: 12px;
    }
  }
  .description__ {
    width: 70%;
    height: 30%;
  }
  .btn__holder {
    width: 75%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .tut1__container {
    zoom: 0.8;
  }
}
