.page__container {
    display: flex;
    height: 80vh;
    width: 95%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
  .top__text,
  .down__text {
    width: 80%;
    // flex-grow: 4;
  }
  .middle__text {
    width: 80%;
    // margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .pdf__ {
      width: 300px;
      // margin-top: 50px;
      // margin-bottom: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      .pdf-content {
        text-align: center;
        cursor: pointer;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        p {
          margin: auto;
        }
      }

      img {
        // width: 50%;
        // height: 50%;
        object-fit: contain;
        cursor: pointer;
      }
      p {
        text-align: center;
      }
    }
  }
  .btn__holder {
    width: 75%;
    display: flex;  
    justify-content: space-between;
  }
}


@media (-webkit-device-pixel-ratio: 1.25) {
    .page__container{
      zoom: 0.8;
    }
  }