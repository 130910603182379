.check-wt-u-did-container {
  .pdf-btn {
    text-align: right;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    width: 130px;
    align-items: center;
    margin: auto;
    cursor: pointer;

    img {
      height: 59px;
      width: 42px;
    }
    span {
      font-size: 12px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      span {
        color: rgb(209, 209, 209);
      }
    }
  }
  .continer-parent {
    display: flex;
    width: 100%;
    // min-height: 88vh;
    // min-height: 865px;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    align-items: flex-start;

    max-height: 700px;

    .right {
      // width: 24%;
      width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-left: 1px solid #707070;
      max-height: 700px;
      overflow: auto;
      &::-webkit-scrollbar {
        color: gray;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 12px;
        width: 10px;
        border: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      .line-vertical {
        width: 1px;
        height: 100%;
        background-color: white;
      }
      .menu {
        height: 100%;
        width: 85%;
        margin: auto;
        display: flex;
        // justify-content: space-between;
        flex-direction: column;
        // background-color: rgba($color: #000000, $alpha: 0.8);
        padding: 15px;
        border-radius: 20px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          color: gray;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 12px;
          width: 10px;
          border: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        color: #0d90f5;

        .title{
          margin-bottom: 20px;
        }

        .block {
          margin-bottom: 15px;
          text-align: left;
          color: white;
          // p {
          //   font-size: 12px;
          // }

          .each-rm-file {
            img {
              height: 59px;
              width: 42px;
            }
            a {
              display: flex;
              flex-direction: column;
              color: white;
              align-items: center;
              margin: auto;
              cursor: pointer;
              font-size: 12px;
              text-decoration: none;

              &:hover {
                transition: 0.3s all ease-in-out;
                color: rgb(209, 209, 209);
              }
            }
          }
        }
      }
      .gone {
        display: none;
      }
    }
    .info-check-conatiner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      height: auto;

      .checkbox-container {
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .check-box-conatiner {
        width: 100%;
        max-height: 500px;
        overflow: auto;
        &::-webkit-scrollbar {
          color: gray;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 12px;
          width: 10px;
          border: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: #3b3b3b;
        }
      }

      .title {
        color: white;
      }

      .check-wt-u-did-btns {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .abandon-btn {
          .custom-next-btn {
            button {
              .MuiButton-label::after {
                content: "\d7";
                border-right: unset;
                border-top: unset;
                height: unset;
                width: unset;
                font-size: 28px;
                transform: rotate(0deg);
                color: #ff6500;
              }
            }
          }
        }
        .custom-next-btn button {
          width: 200px;
        }
      }

      .chaeck-quesion-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 20px;

        .check-box-text {
          svg {
            color: #ff6500;
            font-size: 30px;
          }
        }

        .MuiSvgIcon-root {
          color: #626262de;
          font-size: 30px;
        }
        .MuiSvgIcon-colorPrimary {
          color: #ff6500;
        }
        .check-question {
          width: 350px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .checkbox-style {
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: center;
            display: flex;
          }
          .MuiFormControlLabel-root {
            margin: 0;
          }
          p {
            color: white;
            font-size: 18px;
            margin: 0 0 0 30px;
          }
        }
      }
    }
    .left {
      // width: 24%;
      max-width: 350px;
      padding-left: 20px;
      height: 700px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
  .btns-container {
    text-align: center;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .check-wt-u-did-container {
    zoom: 0.8;
  }
}
