.alert-conatiner {
  position: absolute;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.6);
  width: 100%;
  height: 80vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .the-alert {
    background-color: #434343;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 90%;
    color: white;
    padding: 40px;
    border-radius: 20px;
    .btns-conatiner {
      width: 50%;
      display: flex;
      justify-content: space-around;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .alert-conatiner {
    zoom: 0.8;
  }
}
