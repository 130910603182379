.chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .first-title {
    margin: 0;
    p {
      margin: 0;
    }
  }

  .num-of-param-text {
    // font-size: 24px;
    color: white;
    padding-left: 20px;
    font-size: 12px;
    margin-bottom: 0;
    // width: 55px;
  }

  .sub-container {
    width: 100%;
    display: flex;
    // min-height: 100vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .audit-copyright {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 80px;
        font-size: 14px;
        color: white;
        p {
          margin-bottom: 0px;
        }
      }

    .left {
      width: 18%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left-chart {
        width: 100%;
        margin-left: 10px;
        // height: 100%;
        height: 500px;
        overflow: auto;
        direction: rtl;

        &::-webkit-scrollbar {
          color: gray;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 12px;
          width: 10px;
          border: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        .info-param {
          color: white;
          font-size: 16px;
          text-align: center;
          display: flex;
          justify-content: center;

          span {
            p {
              margin: 0px 10px;
            }
          }
        }

        .data-info-section {
          height: 400px;
          width: 100%;
          background-color: red;
          margin-bottom: 15px;
        }

        .loading-info {
          height: 300px;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .left-hidden-info-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          overflow: auto;
          direction: rtl;
          padding-left: 12px;
          // justify-content: center;
          position: relative;

          .close-left-menu {
            position: absolute;
            right: 0;
            top: 0;
          }

          &::-webkit-scrollbar {
            color: gray;
            width: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 12px;
            width: 10px;
            border: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          .data-info-section {
            height: 400px;
            width: 100%;
            background-color: red;
            margin-bottom: 15px;
          }

          .info-title {
            color: #0d90f5;
            margin-bottom: 3px;
          }

          .info-text {
            color: #c2c2c2;
          }

          .icon-continer {
            height: 5%;
            width: 100%;
            // background-color: white;
            color: white;
            cursor: pointer;
            margin-bottom: 10px;

            svg {
              color: #ff6500;
              transition: all 0.3s ease-in-out;

              &:hover {
                color: #ff0000;
              }
            }
          }

          .info-container {
            height: 90%;
            display: flex;
            width: 100%;
            flex-direction: column;
            overflow-y: auto;
            position: relative;
            margin-left: 15px;

            &::-webkit-scrollbar {
              color: gray;
              width: 10px;
            }

            &::-webkit-scrollbar-thumb {
              background: gray;
              border-radius: 12px;
              width: 20px;
              border: 5px;
            }

            &::-webkit-scrollbar-track {
              background-color: white;
            }

            .data-info-section {
              height: 400px;
              width: 100%;
              background-color: red;
              margin-bottom: 15px;
            }
          }
        }

        .formation-container {
          border-right: 1px solid #707070;
          width: 98%;
          padding-left: 20px;
          border-left: 1px solid #707070;
          padding-right: 20px;

          .text-formation {
            color: #c2c2c2;
            direction: ltr;
          }
        }
      }

      .pdf-icon {
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;

        span p {
          margin: 0;
        }

        &:hover {
          .MuiSvgIcon-root {
            transition: all 0.3s ease-in-out;
            color: #e55b00;
          }

          span {
            transition: all 0.3s ease-in-out;
            color: rgb(207, 207, 207);
          }
        }

        .MuiSvgIcon-root {
          color: #ff6500;
          font-size: 40px;
          margin-left: 40px;
        }

        span {
          color: white;
          font-size: 12px;
          text-align: center;
        }
      }
    }

    .middle {
      width: 80%;
      height: 100%;
      margin-left: 30px;

      .chart-container-middle {
        width: 100%;
        height: 80%;
        display: flex;
        align-items: flex-end;
        color: white;
        .speedchart__container {
          width: 100%;
          // max-width: 500px;
          height: 550px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .risk-value {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            font-size: 24px;
            margin-top: 20px;

            span{
              color: white;
            }
            p{
              margin: 0;
              width: auto;
              font-size: unset;
              margin-right: 6px;
            }
          }
        }
        .first-chart {
          width: calc(100% - 55px);
        }

        .rest-of-charts {
          width: 100%;
        }

        p {
          // text-align: center;
          width: 50px;
          margin: 0;
          font-size: 12px;
        }
      }

      .adie-dang-protec-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .risk-value {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
          color: white;
          font-size: 24px;
        }

        .pdf-section {
          text-align: right;
          margin-right: 20px;
          display: flex;
          color: white;
          width: 30%;
          margin: 0 auto 0 0;
          align-items: flex-start;
          margin-top: 20px;
          padding-left: 40px;

          .pdf-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          img {
            height: 59px;
            width: 42px;
          }
          span {
            font-size: 12px;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            span {
              color: rgb(209, 209, 209);
            }
          }
        }
        .aide-cont {
          // width: 50%;
          display: flex;
          justify-content: center;
          padding-top: 30px;
          width: 43%;
          // width: 50%;

          .custom-next-btn .info-btn .MuiButton-label::after {
            content: unset;
          }

          .info-select-icon {
            height: 40px;
            margin-right: -12px;
          }
        }

        .first-btn {
          justify-content: flex-start;
          width: 55.3%;
        }
        .dang-protec-bg {
          display: flex;
          width: 27%;
          font-size: 14px;
          // margin: auto;
          // justify-content: space-between;
          // justify-content: space-around;
          justify-content: flex-end;
          padding-right: 12px;
          padding-top: 30px;

          .danger-bg,
          .protection-bg {
            width: 20px;
            height: 20px;
            margin: auto;
          }
        }

        .danger-container,
        .protection-container {
          text-align: center;
          color: #c2c2c2;

          p {
            margin: 0;
            padding-top: 3px;
            // font-size: 12px;
          }
        }

        .danger-container {
          padding-right: 12px;
          .danger-bg {
            background: #ff4600;
          }
        }

        .protection-container {
          margin-right: 10px;
          .protection-bg {
            background: #0d90f5;
          }
        }
      }

      .bar-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 10%;
        width: 95%;
        margin-left: 4%;
        color: white;

        div {
          font-size: 12px;
          padding: 5px;
          text-align: center;
          // min-width: 70px;
          max-width: 56px;
          // min-width: 1%;
        }
      }

      .spcific-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 10%;
        width: 95%;
        margin-left: 4%;
      }

      .spcific-bar-paramiters {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 10%;
        width: calc(92% - 55px);
        margin-left: 5.3%;
      }
    }

    .right {
      // width: 20%;
      // height: 500px;
      width: 270px;
      padding-right: 10px;
      border-left: 1px solid #707070;
      height: 100%;
      padding-left: 10px;

      .right-chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .formation-container-right {
          height: 100%;

          .formation-container {
            // margin-top: 50px;
            display: flex;
            // height: 65%;
            justify-content: center;
            flex-direction: column;

            .title-type {
              text-align: center;
              color: #0d90f5;
            }

            .btns-container {
              display: flex;
              flex-direction: column;
              margin-top: 30px;
              text-align: center;

              .create-continue-reminder-container {
                .p-btns {
                  border-bottom: 1px solid #707070;
                  padding-bottom: 8px;
                }

                .create-rm {
                  margin-top: 30px;
                }
              }

              h4 {
                color: #0d90f5;
                margin-bottom: 16px;
              }

              .enable,
              .disabeld {
                cursor: pointer;
              }

              .enable {
                color: white;
              }

              .disabeld {
                color: #7f7f7f;
              }

              .go-to-reminder {
                .MuiButton-label::after {
                  font-weight: 100;
                  content: "\00d7";
                  transform: unset;
                  color: #ff6500;
                  height: unset;
                  border-top: unset;
                  border-right: unset;
                  font-size: 30px;
                }
              }

              button {
                margin-top: 20px;
                width: 100%;
              }
            }

            .chart-types {
              .ul-chart-types {
                padding-left: 0;
                list-style: none;
                color: white;
              }

              .chart-type-li {
                list-style: none;
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 5px;
                border-bottom: solid 1px #363636;

                .chart-type-container {
                  display: flex;
                  flex-direction: row;
                  cursor: pointer;
                  padding: 12px 0px;

                  .type-text {
                    width: 100%;
                    color: white;
                    margin: 0;

                    p {
                      margin: 0;
                    }
                  }

                  .icon {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    // align-items: center;
                  }
                }

                .chosen {
                  color: #ff6500;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .right-menu {
        height: 100%;
        background-color: #242424;
        border-radius: 3px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        color: white;
        overflow: auto;

        &::-webkit-scrollbar {
          color: gray;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 12px;
          width: 10px;
          border: 2px;
        }

        &::-webkit-scrollbar-track {
          background-color: #454545;
        }

        // position: absolute;
        // top: -30%;
        .close-icon-container {
          display: flex;
          width: 100%;
          // height: 10%;
          justify-content: flex-end;

          button {
            margin: 10px 0;

            .MuiButton-label {
              justify-content: flex-end;

              .MuiSvgIcon-colorPrimary {
                color: white;
                transition: all 0.3s;

                &:hover {
                  color: #777777;
                }
              }
            }
          }
        }

        .rest-side-menu {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .download-section {
            min-height: 160px;
            justify-content: space-around;
            border-bottom: 1px solid #707070;
            padding-bottom: 25px;
            margin-bottom: 25px;
          }

          .supervision-section {
            margin-bottom: 25px;
          }

          .supervision-section,
          .roadmap-section {
            min-height: 250px;
            justify-content: space-between;
            border-bottom: 1px solid #707070;
            padding-bottom: 25px;

            .title {
              color: #0d90f5;
            }

            .text {
              color: #d1d1d1;
            }

            .btns {
              .first-btn {
                margin-bottom: 12px;
              }
            }
          }

          .roadmap-section {
            border-bottom: unset;
          }

          .download-section,
          .supervision-section,
          .roadmap-section {
            display: flex;
            flex-direction: column;
            align-items: center;

            .text {
              text-align: center;
            }

            .btns {
              button {
                font-size: 14px;
                height: 45px;
              }
            }
          }
        }
      }

      .btns {
        display: flex;
        justify-content: space-around;

        flex-direction: column;
      }

      .gone {
        display: none;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .chart-container {
    zoom: 0.8;
  }
}
