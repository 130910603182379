.after-finish-wrapper{

  .after-finish-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // width: 70%;
    max-width: 700px;
    margin: auto;
    // height: 600px;
    // min-height: 88vh;
    // min-height: 850px;
    min-height: 650px;

  
    .after-finish-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #e8e8e8;
      text-align: center;
      margin-bottom: 40px;
  
      .title p {
        font-size: 45px;
        margin-bottom: 20px;
      }
  
      p {
        font-size: 25px;
      }
    }
  
    .custom-next-btn button {
      width: 270px;
      margin-bottom: 12px;
    }
  
    .history-btn {
      .MuiButton-label::after {
        content: none;
      }
    }
  }
  .app-info {
    text-align: center;
    // margin-top: 100px;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .after-finish-wrapper {
    zoom: 0.8;
  }
}
