.info-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: auto;
  .title {
    height: auto;
    padding: 0;
    color: white;
    // font-size: calc(10px + 2vmin);
    margin-top: 40px;
    margin-bottom: 40px;

    p {
      margin-bottom: 0;
    }
  }

  .img {
    max-height: 400px;
    margin-bottom: 30px;
  }

  .btns-intro-comtainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .info-intro {
    zoom: 0.8;
  }
}
