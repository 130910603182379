.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 50px;
  padding-top: 20px;

  ul {
    li {
      p {
        margin: 0 !important;
      }
    }
  }

  .logout-continer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    z-index: 5;

    .humberger-container {
      .dropdown-btn {
        background: transparent;
        width: 0;
        padding: 0;
      }
      .huberger-img {
        height: 60px;
      }
    }
    .history-btn {
      text-decoration: none;
      color: white;
      width: 100%;
      text-align: center;
    }

    // min-width: 200px;

    .menu-content {
      .dropdown-btn {
        background: transparent;
        min-width: 200px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-shadow: unset;
        // transition: all .3 ease-in-out;

        &:hover {
          span,
          svg {
            transition: all 0.3s ease-in-out;
            color: #ff6500;
          }
        }
        .avatar-container {
          padding-right: 8px;
          visibility: hidden;
          .makeStyles-small-2 {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .menu-icon {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button {
        border-radius: unset;
        background-color: #0000007a;
        color: #ff6500;
        transition: all 0.3s;

        padding: 10px 20px;

        &:hover {
          color: #ff6500e0;
          background-color: #000000;
        }

        .MuiSvgIcon-root {
          font-size: 30px;
        }
      }
    }
    .text-logout {
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      margin: auto;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
      p {
        margin: 0;
      }
      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }

  .audit-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding-top: 20px;

    img {
      // margin-right: 12px;
      // width: 69.5px;
      height: 40px;
      // margin-right: 16px;
      margin-left: 20px;
    }

    .audit-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.58;
      color: #ff872b;
    }
  }

  .logout-section {
    margin-top: 47px;
    margin-right: 52px;
    cursor: pointer;
    p {
      color: #ff872b;
      margin: 0;
    }
    .logout-icon {
      color: #ff872b;
      font-size: 50px;
    }
  }
}

@media (max-width: 768px) {
  .header-container {
    // .audit-section {
    //   padding-top: 20px;
    // }
    .logout-section {
      margin-top: 20px;
    }
  }
}

@media (max-width: 425px) {
  .header-container {
    .audit-section {
      img {
        // width: 50px;
        height: 25px;
        margin-left: 16px;
      }
      .audit-title {
        font-size: 20px;
      }
    }

    .logout-section {
      margin-right: 16px;
      p {
        font-size: 10px;
      }
      .logout-icon {
        font-size: 30px;
      }
    }
  }
}

// .Component-paper-4 {
//   min-width: 150px !important;
// }

.header__history {
  height: 30px;
  text-align: center;
  margin: 0;
  padding: 0;

  p {
    padding: 0;
    margin: 0 !important;
    a {
      width: 30% !important;
    }
  }
}

.MuiListItem-gutters {
  display: flex !important;
  justify-content: center !important;
  &:hover {
    transition: all 0.3s ease-in-out;
    color: #ff6500;
  }
}

.MuiPopover-paper {
  background: #2a2a2a !important;
  color: white !important;
}

// .MuiMenu-paper {
//   width: 200px;
// }

@media (-webkit-device-pixel-ratio: 1.25) {
  .header-container {
    zoom: 0.8;
  }

  .MuiMenu-list {
    zoom: 0.8;
  }
}
