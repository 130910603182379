.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  position: relative;

  .v-line-table-right {
    position: absolute;
    right: 35%;
    height: 100%;
  }
  .v-line-table-left {
    position: absolute;
    left: 25%;
    height: 100%;
  }
  td,
  th {
    border-bottom: 0;
  }

  .risk-value {
    font-size: 26px;
    margin: 0;
    font-weight: bold;

    span {
      font-size: 18px;
    }
    p {
      margin: 0;
      width: auto;
      font-size: unset;
      margin-right: 0px;
    }
  }

  .title {
    font-size: 22px;
    border-bottom: 0;
  }

  .custom-next-btn {
    display: flex;
    justify-content: center;

    button {
      font-size: 14px;
      font-weight: 400;
      height: 45px;
      max-width: 180px;
    }
  }
}
.roadmap__type {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.type__name {
  text-align: center;
  width: 100%;
  color: white;
  margin: 0;
  font-size: 20px;
}

.type__name_title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #0d90f5;
  margin-bottom: 20px;
}

.speedmeter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 0;
  position: relative;
  margin-top: 14px;

  p {
    display: flex;
  }

  span {
    color: white;
    font-size: 28px;
    margin-right: 4px;
  }
  img {
    position: absolute;
  }
  .v-line-table-right {
    right: 0;
  }
  .v-line-table-left {
    left: 0;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .container {
    zoom: 0.8;
  }
}
