.tut2__container{
    width: 100%;
    // height: 100%;
    height: 80vh;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    color: white;
    position: relative;
    .text {
        width: 70%;
        margin: 0;
        // text-align: center;
    }
    .bottom-text {
        width: 45%;
        margin: 0;
    }
    .barchart__parent {
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }
    .btn__holder {
        width: 75%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
    }
}


@media (-webkit-device-pixel-ratio: 1.25) {
    .tut2__container {
      zoom: 0.8;
      .barchart__parent {
        width: 80%;
    
        svg{
          // height: 250px;
        }
      }
    }
  }