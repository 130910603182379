.app-info-container {
  border-radius: 20px;
  // border: 2px solid #ff6803;
  // border: 1px solid #707070;
  padding: 15px;
  max-width: 360px;
  margin: 0 auto;
  position: relative;

  .left-line {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
  }

  .right-line {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    .logo {
      height: 70px;
    }
    .title {
      color: #ff6803 !important;
      margin-left: 12px !important;
      margin-bottom: 0 !important;
      p {
        margin: 0;
      }
    }
  }
  .text {
    color: white;
  }
}

// @media (-webkit-device-pixel-ratio: 1.25) {
//   .app-info-container {
//     zoom: 0.8;
//   }
// }
