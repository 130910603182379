.profile-conatainer {
  width: 100%;
  // height: 80vh;
  display: flex;
  // justify-content: space-around;
  justify-content: center;
  align-items: center;
  .left {
    width: 300px;
    display: flex;
    flex-direction: column;
    // align-items: center;

    .title {
      font-size: 22px;
      color: #fff;
      margin-bottom: 20px;
    }
  }

  .right {
    // justify-self: flex-start;
    // width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 180px;

    span {
      font-weight: normal;
    }

    .img-size-text {
        font-size: 12px;
        margin-top: 12px;
        color: #969692;
        margin-bottom: 20px;
    }

    button {
        width: 140px;
    }
    .validate-btn {
      color: #fff;
      background-color: #ff6500;
      border: none;
      height: 50px;
      border-radius: 0.3rem;
    }

    .browse {
        background: linear-gradient(to bottom, #464646, #242424);
        border: none;
        color: white;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .profile-conatainer {
    zoom: 0.8;
  }
}
