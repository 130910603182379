.finish-content {

  .finish-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    // min-height: 88vh;
    // min-height: 862px;
    max-width: 700px;
    margin: auto;
  
    .title-container {
      // margin-bottom: 0;
      // padding-top: 0;
      h2 {
        font-size: 40px;
        color: #a7a7a7;
      }
    }
    .line-horizontal {
      width: 60%;
      height: 1px;
      margin: auto;
      margin-bottom: 30px;
      margin-top: 30px;
      border: solid 1px #707070;
    }
    .info-container {
      margin-bottom: 30px;
      font-size: 25px;
      line-height: 1.33;
      color: #e8e8e8;
    }
    .input-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
  
      .app-info {
        margin-top: 100px;
      }
  
      p {
        font-size: 24px;
        line-height: 1.33;
        color: #a2a2a2;
      }
      .input {
        outline: none;
        border: 0px;
        margin: auto;
        border-radius: 10px;
        background-color: #cecece;
        width: 450px;
        padding: 0 20px;
        height: 50px;
      }
  
      .btn {
        margin-top: 40px;
        width: 220px;
        .MuiButton-label::after {
          content: "\2713";
          color: #ff6500;
          font-size: 24px;
          width: 0;
          height: unset;
          border-right: unset;
          border-top: unset;
          transform: unset;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .finish-container {
      margin-top: 30px;
      .title-container {
        h2 {
          font-size: 35px;
        }
      }
  
      .input-container {
        .input {
          width: 100%;
          padding: 16px 20px;
        }
        p {
          font-size: 18px;
        }
        // .btn {
        //   width: unset;
        //   font-size: unset;
        //   padding: 10px 40px 10px 60px;
        // }
      }
  
      .info-container {
        font-size: 25px;
      }
    }
  }
  
  @media (max-width: 425px) {
    .finish-container {
      .title-container {
        h2 {
          font-size: 25px;
        }
      }
  
      .input-container {
        width: 90%;
        height: unset;
        .input {
          width: 100%;
          padding: 16px 20px;
        }
        p {
          font-size: 18px;
        }
      }
  
      .info-container {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .finish-content {
    zoom: 0.8;
  }
}
