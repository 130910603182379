.footer-container {
  width: 100%;
  background-image: linear-gradient(to bottom, #2e2e2e, rgba(77, 73, 73, 0.09));
  text-align: right;
  // background-color: red;
  margin-top: 10px;
  button {
    color: #ffffff;
    background-color: #211300;
    border: none;
    padding: 5px 30px 6px 37px;
    // background-image: linear-gradient(to bottom, #0d90f5, #07487b);
    clip-path: polygon(100% -285%, 0 100%, 100% 100%);
    svg {
      margin-right: 4px;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .footer-container {
    // button {
    //   padding: 2px 15px 2px 30px;
    // }
    zoom: 0.8;
  }
}
