.container {
  position: relative;

  .img {
    object-fit: cover;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
  .icon-container {
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: red;
    border-radius: 50%;
    top: 190px;
    left: 210px;
    cursor: pointer;

    &::before {
      content: "\d7";
      border-right: unset;
      border-top: unset;
      height: unset;
      width: unset;
      font-size: 28px;
      transform: rotate(0deg);
    //   color: #ff6500;
    color: white;
    }
  }
}
