@media (-webkit-device-pixel-ratio: 1.25) {
    .speed-chart {
      height: 25% !important;
      width: 25% !important;
    }
    .main-chart {
        height: 35% !important;
        width: 35% !important;
      }
  }
  