.after-finish-wrapper {
  .after-finish-roadmap-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 70%;
    // margin: auto;
    // min-height: 88vh;
    // min-height: 858px;
        // min-height: 862px;



    .app-info {
      margin-top: 100px;
    }

    .after-finish-roadmap-text {
      border: 2px solid #707070;
      padding: 40px;
      border-radius: 42px;
      color: #959595;
      font-size: 22px;
      max-width: 500px;
      text-align: center;
      .custom-next-btn button {
        //   width: 270px;
        margin: auto;
        margin-top: 30px;
      }
    }

    .after-finish-roadmap-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #e8e8e8;
      text-align: center;
      // margin-bottom: 40px;

      h1 {
        font-size: 45px;
        margin-bottom: 20px;
      }

      p {
        font-size: 25px;
      }
    }

    .custom-next-btn button {
      width: 270px;
      margin-bottom: 12px;
    }

    .history-btn {
      .MuiButton-label::after {
        content: none;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .after-finish-wrapper {
    zoom: 0.8;
  }
}
