.questions-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .middle-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 5%;

    .bar-container {
      width: 100%;
      position: relative;
      margin-bottom: 100px;
      margin-left: 22%;
      margin-top: 12px;
      .line {
        width: 65%;
        height: 1px;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #707070;
      }
      .dots {
        width: 55%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 5%;
        .dot {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background-color: transparent;
        }
        .colored-dot {
          background-color: transparent;
        }
      }
    }
  }

  .question-formation {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    color: white;
    margin-top: 20px;
    align-items: flex-start;
    min-height: 630px;

    .full-info {
      max-height: 600px;
      // padding: 40px 4px 33px 25px;
      padding: 0;
      opacity: 0.85;
      // border-radius: 28px;
      // background-color: #2a2a2a;
      position: relative;

      .info-bg {
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
          border-radius: 5px;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 5px;
          width: 5px;
          border: 5px;
        }
      }
      // .close-container {
      //   // display: flex;
      //   // justify-content: flex-end;
      //   position: fixed;
      //   margin-top: -35px;
      //   margin-left: 130px;
      //   .icon {
      //     color: white;
      //     justify-self: right;
      //   }
      // }
    }

    .formation-open {
      background-color: #2a2a2a;
      border-radius: 28px;
    }

    .formation-container {
      width: 15%;
      min-height: 600px;
      padding-left: 20px;
      border-left: 1px solid #7070708c;

      .close-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;

        .icon {
          color: white;
          justify-self: right;
        }
      }

      .glossary-btn {
        font-weight: bold;
        border-radius: 45px;
        color: #d1d1d1;
        border: none;
        margin-bottom: 12px;
        padding-left: 0;
        font-size: 18px;

        p {
          margin: 0;
        }

        &::before {
          position: relative;
          content: "";
          display: inline-block;
          width: 15px;
          height: 13px;
          border-right: 2px solid #ff6500;
          border-top: 2px solid #ff6500;
          transform: rotate(45deg);
          margin-right: 10px;
        }
      }
    }
    .formation {
      // width: 15%;
      // padding-left: 20px;
      // border-left: 1px solid #7070708c;

      max-height: 600px;
      padding-bottom: 20px;
      overflow: auto;
      &::-webkit-scrollbar {
        border-radius: 5px;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 5px;
        width: 5px;
        border: 5px;
      }

      // .glossary-btn {
      //   font-weight: bold;
      //   border-radius: 45px;
      //   color: #d1d1d1;
      //   border: none;
      //   margin-bottom: 12px;
      //   padding-left: 0;
      //   font-size: 18px;

      //   &::before {
      //     position: relative;
      //     content: "";
      //     display: inline-block;
      //     width: 15px;
      //     height: 13px;
      //     border-right: 2px solid #ff6500;
      //     border-top: 2px solid #ff6500;
      //     transform: rotate(45deg);
      //     margin-right: 10px;
      //   }
      // }

      .text-info-container {
        height: 100%;
        // padding-right: 12px;
        font-size: 16px;
        color: #707070;
      }
      .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding-top: 0;

        h5 {
          color: #0d90f5;
          font-size: 18px;
        }
        .line-horiz {
          width: 70%;
          height: 1px;
          background-color: #707070;
          opacity: 0.4;
          margin-bottom: 5px;
          margin-left: 5px;
        }
      }
    }

    .quistion-container {
      display: flex;
      justify-content: flex-end;
      width: 80%;
      .question-title-chosses {
        width: 80%;
        text-align: center;
        padding-right: 12px;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .subtitle {
          margin-top: 12px;
        }

        .btns-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          margin: 40px 0;

          .custom-next-btn .info-btn .MuiButton-label::after {
            content: unset;
          }
          .info-select-icon {
            height: 40px;
            margin-right: -12px;
          }

          .skip-question {
            .custom-next-btn {
              button {
                .MuiButton-label::after {
                  // content: "\274c";
                  // content: "X";
                  content: "\d7";
                  border-right: unset;
                  border-top: unset;
                  height: unset;
                  width: unset;
                  font-size: 28px;
                  transform: rotate(0deg);
                  color: #ff6500;
                }
              }
            }
          }
        }

        .title {
          color: white;
          margin-bottom: 0;
          font-size: 26px;
        }
        .answers {
          .answer {
            display: flex;
            // justify-content: space-between;
            justify-content: space-around;
            align-items: center;
            margin-top: 15px;
            list-style: none;
            cursor: pointer;
            color: #c2c2c2;
            position: relative;
            .previously_selected {
              position: absolute;
              left: -150px;
              cursor: default;
              max-width: 150px;
            }
            .prev-selected-choice {
              background: #034795;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              // color: #034795;
            }

            .selected-icon {
              color: #ff6500;
            }

            p {
              margin-bottom: 0%;
            }
            .answer-text {
              text-align: left;
              width: 90%;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .questions-page {
    .question-formation {
      flex-direction: column;
      align-items: center;

      .full-info {
        margin-top: 0;
      }

      .formation {
        width: 95%;

        .header-container {
          margin-bottom: 0px;
        }
      }

      .quistion-container {
        justify-content: center;
        width: 95%;

        .question-title-chosses {
          width: 100%;
        }
      }
    }
    .middle-bar {
      .bar-container {
        margin-left: 25%;
      }
    }
  }
}

@media (max-width: 425px) {
  .questions-page {
    .question-formation {
      .formation {
        .text-info-container {
          font-size: 15px;
          button {
            padding: 4px 20px;
            font-size: unset;
          }
        }
      }
      .quistion-container {
        .go-next-btn {
          margin-top: 30px;
          padding: 4px 7px 4px 23px;
          font-size: unset;
        }

        .question-title-chosses {
          padding-right: 0;
          ul {
            padding-left: 10px;
            margin: auto;
            width: 90%;
          }
          .answers .answer {
            margin-top: 10px;
            font-size: 12px;
            svg {
              padding-right: 4px;
            }
          }
          .title {
            margin-bottom: 20px;
            font-size: 30px;
          }
        }
      }
    }
    .middle-bar {
      .bar-container {
        margin-bottom: 40px;
        margin-left: 15%;
        .line {
          width: 80%;
        }
        .dots {
          width: 70%;
          .dot {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .questions-page {
    zoom: 0.8;
  }
}
