.openReport {
  font-size: 12px !important;
  max-width: 200px !important;
  height: 45px !important;
  @media (max-width: 800px) {
    width: 150px !important;
  }
  @media (max-width: 750px) {
    width: 120px !important;
  }
}

.customTableStyle {
  border-radius: 4px !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
  .rdt_TableBody {
    overflow-x: auto !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      color: gray;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 12px;
      width: 10px;
      border: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #3b3b3b;
    }
  }
}

.improvement-container {
  padding: 0 94px;
}

.disabledButton {
  opacity: 0.6;
}

.audit-copyright {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
  color: white;
  p {
    margin-bottom: 0px;
  }
}
