.completed-roadmap-wrapper {
  .title {
    margin-bottom: 30px;
    text-align: center;
    color: white;
    font-size: 32px;
  }
  .completed-roadmap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    justify-content: space-between;
    margin: auto;
    max-width: 800px;
    min-height: 400px;
    // padding: 0 70px;

    .left-line {
      position: absolute;
      left: 0;
    }
    .right-line {
      position: absolute;
      right: 0;
    }

    .text {
      margin-bottom: 30px;
      font-size: 18px;
    }
    .app-info {
      margin-top: 100px;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .completed-roadmap-wrapper {
    zoom: 0.8;
  }
}
