.process-conatiner {
  .procces-actions {
    .chocies-container {
      // max-height: 400px;
      // overflow: auto;
      margin-bottom: 60px;
      margin-top: 50px;
      // &::-webkit-scrollbar {
      //   color: gray;
      //   width: 5px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background: gray;
      //   border-radius: 12px;
      //   width: 10px;
      //   border: 2px;
      // }

      // &::-webkit-scrollbar-track {
      //   background-color: #454545;
      // }
    }

    .sub-titles-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      .type {
        text-align: center;
        color: white;
        margin-bottom: 0;
        p {
          margin-bottom: 0;
        }
        font-size: 24px;
        width: 75%;
        // margin: auto;
        text-align: center;
      }
    }
    .title {
      margin-bottom: 60px;
      color: white;
      text-align: center;
      font-size: 32px;
    }
    // .type {
    //   text-align: center;
    //   color: white;
    //   margin-bottom: 40px;
    //   font-size: 24px;
    //   width: 75%;
    //   margin: auto;
    //   text-align: center;
    // }
    .procces-title,
    .action-title {
      text-align: center;
      color: white;
    }
  }
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  .progressBar-conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .bar-container {
      width: 100%;
      position: relative;
      margin-bottom: 60px;
      margin-left: 22%;
      margin-top: 12px;
      .line {
        width: 65%;
        height: 1px;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #707070;
      }

      .dots {
        width: 55%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 5%;
        .dot {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          // background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          // color: white;
        }
        .colored-dot {
          // background-color: #ff6500;
        }
      }
    }
  }

  .btn-conatiner {
    display: flex;
    justify-content: space-around;
    max-width: 700px;
    margin: 20px auto;
    svg {
      margin-top: 30px;
    }
    .custom-next-btn button {
      margin: auto;
    }

    .custom-next-btn .info-btn .MuiButton-label::after {
      content: unset;
    }

    .info-select-icon {
      height: 40px;
      margin-right: -12px;
    }
  }
  .line-horizontal {
    width: 100%;
    height: 1px;
    background-color: #707070;
  }
}

.choise-conatiner {
  // width: 100%;
  width: 80%;
  cursor: pointer;
  height: 100%;
  margin: auto;
  // padding-left: 5px;
  border-bottom: 1px solid #414141;
  .choice-text-icon {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    // font-size: 15px;
    .left-of-choice {
      width: 35%;
      height: 100%;
      position: absolute;
      left: -45%;

      .left-subpart {
        display: flex;
        flex-direction: row;
        align-items: center;
        .pcolor {
          p {
            color: #034795;
          }
        }
        .validated_text {
          p {
            color: #034795;
            font-size: 18px;
          }
        }

        .pnotcolor {
          p {
            color: white;
          }
        }
      }
      p {
        font-size: 12px;
        color: white;
        margin: 0 2px -12px 0;
        // color: #a7a7a7;
      }
    }
    .icon-text {
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      img {
        height: 20px;
      }
      .prev-selected-choice {
        background: #034795;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // color: #034795;
      }
      .choise-text {
        width: 90%;
        font-weight: 600;
      }
    }
  }
}
.info-of-choice {
  // width: 100%;
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  justify-self: center;
  align-items: center;
  margin: auto;
  border-bottom: 1px solid #414141;
  padding-bottom: 15px;
  // color: gray;
  img {
    position: absolute;
    height: 59px;
    width: 42px;
    cursor: pointer;
    right: -25%;
  }
  p {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    font-weight: 600;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .process-conatiner {
    zoom: 0.8;
  }
}
