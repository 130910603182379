.finish-roadmap-wrapper {
  .finish-contianer {
    display: flex;
    // width: 100%;
    // height: 70vh;
    // justify-content: space-around;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // min-height: 88vh;
    min-height: 862px;
    max-width: 70%;
    margin: auto;

    .header-conatiner {
      display: flex;
      // justify-content: space-around;
      flex-direction: column;
      height: 30%;
      width: 100%;
      align-items: center;

      h3 {
        color: white;
      }
      .header {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        width: 65%;

        h1 {
          color: white;
          font-size: 50px;
        }
        .line {
          width: 30%;
          height: 1px;
          background-color: #707070;
        }
      }
    }
    .middle {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 450px;

      // p {
      //   width: 30%;
      //   text-align: center;
      //   color: white;
      // }
      p {
        color: #a2a2a2;
        text-align: center;
      }

      .input-container {
        width: 100%;
        .input {
          outline: none;
          border: 0px;
          margin: auto;
          border-radius: 10px;
          background-color: #cecece;
          width: 100%;
          padding: 0 20px;
          height: 50px;
        }
      }
    }

    .btn {
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      width: 500px;
      // width: 200px

      .custom-next-btn .info-btn .MuiButton-label::after {
        content: unset;
      }

      .info-select-icon {
        height: 40px;
        margin-right: -12px;
      }
      .MuiButton-label::after {
        // content: "\2713";
        // color: #ff6500;
        // font-size: 24px;
        width: 0;
        height: unset;
        border-right: unset;
        border-top: unset;
        transform: unset;
      }

      .validate-btn {
        .MuiButton-label::after {
          content: "\2713";
          color: #ff6500;
          font-size: 24px;
          width: 0;
          height: unset;
          border-right: unset;
          border-top: unset;
          transform: unset;
        }
      }
    }
  }
  .app-info {
    // margin-top: 100px;
    text-align: center;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .finish-roadmap-wrapper {
    zoom: 0.8;
  }
}
