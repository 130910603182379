.threats-chart {
  .realtime_info {
    color: white;
    margin-top: 20px;
    font-size: 16px;
    min-width: 220px;

    .p-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: 20px;

      .value {
        font-size: 22px;
        font-weight: bold;
        color: #ed6c02;
      }

      .empty-val {
        font-size: 22px;
        font-weight: bold;
        color: #ed6c02;
      }

      p {
        margin: 0;
        padding-right: 6px;
      }
    }
  }
  .container-checkbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // min-height: 90vh;

    // justify-content: space-around;
    // justify-content: center;

    // min-height: 90vh;
    // min-height: 100vh;

    .bar-chart-conatiner {
      width: 100%;
      height: 80%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      // margin: auto;

      .bar-chart {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
      }

      .bar-name {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 10%;
        width: 80%;
        color: white;
        margin-bottom: 10px;

        div {
          font-size: 12px;
        }
      }

      .spcific-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        width: 80%;
      }
    }
    .btn-conatiner {
      // width: 100%;
      // height: 5%;
      // margin: auto;
      min-width: 600px;
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      svg {
        // margin-top: 50px;
        margin: 0;
      }

      .custom-next-btn .info-btn .MuiButton-label::after {
        content: unset;
      }

      .info-select-icon {
        height: 40px;
        margin-right: -12px;
      }
    }
    .title-container {
      width: 100%;
      margin-bottom: 90px;
      text-align: center;
      color: white;
    }
  }

  .app-info {
    // margin-top: 100px;
    height: 900px;
    // min-height:90vh ;
    // margin-bottom: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    background: #010101;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .threats-chart {
    zoom: 0.8;
  }
}
