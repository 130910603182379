.abandon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-height: 90vh;
  justify-content: center;
//   max-width: 700px;
  margin: auto;

  .btns-container {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .custom-next-btn .back-to-list .MuiButton-label::after {
      content: "\d7";
      border-right: unset;
      border-top: unset;
      height: unset;
      width: unset;
      font-size: 28px;
      transform: rotate(0deg);
      color: #ff6500;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .abandon-container {
    zoom: 0.8;
  }
}
