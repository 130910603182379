.tut4__container {
  width: 100%;
  // height: auto;
  height: 78vh;
  display: flex;
  margin-bottom: 2vh;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  
  .text {
    width: 70%;
    margin: 0;
    // margin-top: 40px;
    p {
      margin-bottom: 0;
    }
  }

  .bottom-text {
    width: 45%;
    margin: 0;
    // margin-top: 40px;
    p {
      margin-bottom: 0;
    }
  }

  h1 {
    margin: 0;
    p {
      margin: 0;
    }
  }
  .barchart__parent {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    .chartnames__ {
      // position: absolute;
      width: 75%;
      padding-left: 60px;
      
      display: flex;
      justify-content: space-around;
      
      p {
        font-size: xx-small;
        width: 40px;
      }
    }
  }
  .btn__holder {
    width: 75%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    height: auto;
    bottom: 0;
  }
  .middle__tut {
    margin: 0;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .tut4__container {
    zoom: 0.8;

  .barchart__parent {
    width: 80%;

    svg{
      // height: 250px;
    }

    .chartnames__ {
     

      p {
        font-size: 11px;
     
      }
    }
  }

  }
}
