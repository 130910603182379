.finish-modal {
    display: flex;
    flex-direction: column;
    .info-modal-content {
      max-width: 700px;
      margin: auto;
      text-align: center;
  
      .img-text-content {
        position: relative;
        height: 100%;
        text-align: center;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  
    .right-line,
    .left-line {
      position: absolute;
    }
  
    .right-line {
      right: 0;
      top: 0;
      height: 100%;
    }
    .left-line {
      left: 0;
      top: 0;
      height: 100%;
    }
    .MuiDialog-paperWidthSm {
      min-width: 800px;
      min-height: 600px;
      border: 1px solid #8d8d8d;
    }
  
    .MuiDialogContent-root {
      padding: 8px 55px;
      color: white;
      text-align: left;
      display: flex;
      align-items: center;
  
      .MuiTypography-colorTextSecondary {
        color: white;
      }
    }
    .MuiDialog-paperScrollPaper {
      background-image: url("../../../../images/audit-bg.jpeg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      justify-content: space-around;
  
      .MuiDialogTitle-root {
        text-align: center;
        color: white;
        padding-top: 0;
  
        .MuiTypography-h6 {
          font-size: 30px;
        }
      }
  
      // min-width: 715px;
      // height: 300px;
  
      .MuiDialogActions-root {
        justify-content: center;
        .ok-btn {
          width: unset;
          padding: 30px;
        }
      }
  
      // .close-icon::after {
      //   content: "\d7";
      //   font-size: 32px;
      //   transform: rotate(0deg);
      //   color: #ff6500;
      // }
  
      .close-icon {
        position: absolute;
        top: 3px;
        right: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #a7a7a7;
        transition: all 0.3s ease-in-out;
  
        //   &:hover {
        //     // transition: all .3 ease-in-out;
        //     ::after {
        //       color: red;
        //     }
        //   }
  
        &::after {
          content: "\d7";
          font-size: 32px;
          transform: rotate(0deg);
          color: #ff6500;
          transition: all 0.3s ease-in-out;
        }
  
        &:hover::after {
          color: red;
        }
      }
  
      //   .btns-conatiner {
      //     text-align: center;
  
      //     button {
      //       background: #ff872b;
      //       width: 180px;
      //       height: 50px;
      //       color: white;
      //       font-size: 20px;
      //       font-weight: bold;
      //     }
      //   }
      // }
    }
  }
  
  @media (-webkit-device-pixel-ratio: 1.25) {
    .finish-modal {
      zoom: 0.8;
    }
  }
  