.process-modal-container {
  display: flex;
  flex-direction: column;

  .modal-wrapper {
    // max-height: 700px;
    // padding-top: 30px;
    // padding-bottom: 30px;
    // text-align: center;
    max-height: 700px;
    padding-top: 30px;
    min-height: 500px;
    align-items: center;
    padding-bottom: 30px;
    text-align: center;
    flex-direction: column;
    overflow: auto;
    display: flex;

    overflow: auto;
    &::-webkit-scrollbar {
      color: gray;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 12px;
      width: 10px;
      border: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .info-modal-content {
    max-width: 700px;
    margin: auto;
    text-align: center;

    .img-text-content {
      position: relative;
      height: 100%;
      text-align: center;
    }
  }

  .right-line,
  .left-line {
    position: absolute;
  }

  .right-line {
    right: 0;
    top: 0;
    height: 100%;
  }
  .left-line {
    left: 0;
    top: 0;
    height: 100%;
  }
  .MuiDialog-paperWidthSm {
    min-width: 1100px;
    min-height: 700px;
    border: 1px solid #8d8d8d;
  }

  .MuiDialogContent-root {
    padding: 8px 55px;
    color: white;
    text-align: left;

    .MuiTypography-colorTextSecondary {
      color: white;
    }
  }
  .MuiDialog-paperScrollPaper {
    background-image: url("../../../../images/audit-bg.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: space-around;

    .MuiDialogTitle-root {
      text-align: center;
      color: #ff6500;
      // padding-top: 0;

      .MuiTypography-h6 {
        font-size: 20px;

        span {
          display: flex;
          justify-content: center;
          p {
            margin-bottom: 0;
            margin-right: 4px;
          }
        }
      }
    }

    .MuiDialogActions-root {
      justify-content: center;
      .ok-btn {
        background-color: #ff872b;
        color: white;
        width: 130px;
        height: 40px;
        font-size: 20px;
        margin-bottom: -25px;
      }
    }

    .close-icon {
      position: absolute;
      top: 3px;
      right: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #a7a7a7;
      transition: all 0.3s ease-in-out;

      &::after {
        content: "\d7";
        font-size: 32px;
        transform: rotate(0deg);
        color: #ff6500;
        transition: all 0.3s ease-in-out;
      }

      &:hover::after {
        color: red;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .process-modal-container {
    zoom: 0.8;
  }
}
