.change-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: auto;


  form {
    width: 500px;
    color: white;

    .title {
        width: 78%;
        margin: 0 0 40px auto;
        font-size: 22px;
    }

    input {
      height: 45px;
    }
  }

  .input-content {
    display: flex;
    p {
      width: 20%;
      margin-right: 12px;
    }
    input {
      width: 80%;
      margin-bottom: 20px;
    }
  }

  .validate-btn {
    font-weight: normal;
    border: none;
    margin-left: 108px;
    height: 50px;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .change-name-container {
    zoom: 0.8;
  }
}
