.term-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .info-3-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // margin-bottom: 22px;
    min-height: 165px;
    p {
      margin-bottom: 0;
    }
    img {
      margin-right: 12px;
    }
  }

  hr {
    width: 80%;
    border: solid 1px #484848;
    margin-top: 70px;
  }
  .possiblities-container {
    width: 60%;
    height: 300px;
    .line-1 {
      width: 100%;
      height: 1px;
      background-color: gray;
    }
    .data-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-data {
        width: 45%;
        height: 90%;
      }

      .left-data,
      .right-data {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        p {
          font-size: 21px;
          text-align: center;
          color: #d1d1d1;
        }
      }
      .line-2 {
        width: 1px;
        height: 90%;
        background-color: gray;
      }
      .right-data {
        width: 45%;
        height: 90%;
      }
    }
  }

  .statistics-container {
    width: 60%;
    // height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .statistics-text {
      width: 100%;
      // height: 200px;
      font-size: 16px;
      text-align: left;
      color: #ffffff;
      /* margin-top: 40px;*/
      line-height: 1.2 !important;
    }
    .statistics-pic {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      //   height: 250px;
      margin-top: 50px;
    }
  }

  .terms1-btn {
    margin-top: 50px;
  }

  .check-box-conatianer {
    display: flex;
    align-items: center;
    color: white;
    label,
    p {
      margin: 0;
    }
  }

  .terms-2-btns {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;

    .accepted {
      background-color: #ff6500 !important;
    }

    .accept-btn {
      background: transparent;
      border: 2px solid white;

      .MuiButton-label::after {
        content: unset;
      }
    }
  }

  .terms-3-btns {
    margin-top: 50px;
    width: 50%;

    .previos {
      // padding: 15px 55px 15px 60px;
      padding: 8px 30px 8px 30px;
    }

    button:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, #464646, #242424);
      margin-left: 100px;

      span {
        margin-left: -10px;
      }
    }
  }

  .term-4-title {
    display: flex;
    align-items: center;
    min-height: 165px;
    width: 100%;
    justify-content: center;
    p {
      margin: 0;
    }
  }
  .title {
    height: auto;
    padding: 0;
    color: white;
    font-size: calc(10px + 2vmin);
    margin-bottom: 67px;
    p {
      margin-bottom: 0;
    }
  }
  .checkbox-textarea {
    display: flex;
    justify-content: center;
    width: 60%;
    flex-direction: row;
    margin-bottom: -7px;

    .check-box-conatianer {
      margin-top: -12px;
    }

    .terms-p p {
      font-size: 16px;
      color: #ffffff;
    }
    .terms-2-p {
      padding: 45px 50px;
      border: solid 1px #e9e9e9;
      background-color: #e9e9e9;
      max-height: 331px;
      overflow: scroll;

      p {
        color: #707070;
      }
    }
  }
  .terms-4-btns {
    width: 50%;
    margin-top: 50px;
    .btn-container {
      display: flex;
      // justify-content: center;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
    }
  }

  .terms-5-btns {
    width: 50%;
    margin-top: 50px;
  }

  .terms-6-btns {
    margin-top: 50px;
  }

  .btn-container {
    display: flex;
    // justify-content: center;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;

    // :hover

    // .accepted {
    //   background-color: #ff6500;
    // }

    .right-arrow {
      color: #ff6500;
      font-size: 35px;
    }
  }
  .term-text {
    width: 100%;
    min-height: 300px;
    resize: none;
    scroll-behavior: auto;
    outline: none;
    margin-top: 40x;
    margin-bottom: 50px;
  }
  .check-box-text {
    color: white;
  }
  .check-box {
    color: blanchedalmond;
  }

  .accept-next-btn {
    margin-top: 80px;

    button {
      font-weight: bold;
      font-size: 20px;
      font-family: Roboto;
      border-radius: 45px;
      // padding: 15px 45px 15px 75px;
      padding: 8px 20px 8px 52px;
      color: #d1d1d1;

      &:hover {
        color: #ff872b;
      }
    }

    .previos,
    .skip {
      background: linear-gradient(to bottom, #464646, #242424);
      border: none !important;
    }

    .previos {
      // padding: 15px 55px 15px 60px;
      padding: 8px 30px 8px 30px;
    }

    button:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, #464646, #242424);
      margin-left: 100px;

      span {
        margin-left: -10px;
      }
    }
    button:nth-child(1) {
      border: solid 2px #d1d1d1;
      span {
        margin-left: -20px;
      }
    }
  }

  .skip-btn {
    margin-top: 50px;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 30px 11px 45px;
      border-radius: 45px;
      box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.44);
      border: solid 1px rgba(110, 110, 110, 0.13);
      background: linear-gradient(to bottom, #464646, #242424);
      font-family: Roboto;
      font-size: 20px;
      color: #d1d1d1;
      font-weight: bold;
    }
  }
}

@media (max-width: 768px) {
  .term-container {
    .title {
      margin: 12px 0 28px;
    }
    .checkbox-textarea {
      width: 100%;
      padding: 0 20px;

      .terms-p p {
        font-size: 12px;
      }
    }
    .terms-3-btn {
      width: 100%;
    }
  }
}

@media (max-width: 425px) {
  .term-container {
    .term-4-title,
    .info-3-title {
      min-height: 140px;
    }

    .btn-container {
      justify-content: space-around;
    }

    .title {
      margin: 10px auto 42px;
      width: 90%;
      text-align: center;
    }

    .term-4-title,
    .info-3-title {
      min-height: 140px;
    }

    .info-3-title {
      // margin-bottom: 12px;
      // margin-top: 20px;

      img {
        width: 70px;
      }
    }
    .statistics-container {
      width: 90%;
      .statistics-text {
        margin-top: 12px;
        font-size: 12px;
      }
    }
    .possiblities-container {
      width: 95%;
      height: 300px;

      .data-container {
        .right-data,
        .left-data {
          img {
            width: 70px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }

    .checkbox-textarea {
      .terms-2-p {
        padding: 20px 20px;
        p {
          font-size: 12px;
        }
      }
    }
    .terms-3-btn {
      margin-top: 40px;
      width: 100%;

      button {
        height: 44px;
        width: 140px;
        font-size: 15px;
      }

      .previos {
        padding: 5px 35px 5px 40px;
        font-size: 15px;
      }
    }
  }
}
.checkbox-styles{
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex; 

  .disabled-checkbox{
    width: 30px;
    height: 22px;
    background: black;
    margin-top: 16px;
    border-radius: 4px;
    border: 2px solid #565656;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .term-container {
    zoom: 0.8;
  }
}
