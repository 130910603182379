.page-1-6 {
  .main-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin: auto;
    .main-info-p {
      margin-top: 40px;
      margin-bottom: 40px;
      color: white;
    }
  }
  .next-btn {
    .custom-next-btn button {
      margin: auto;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .page-1-6 {
    zoom: 0.8;
  }
}
