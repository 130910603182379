body {
  background-image: url("../src/images/audit-bg-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  overflow: auto;
  iframe[style*="2147483647"] {
    display: none !important;
  }
  // background-image: url("../src/images/audit-bg.jpeg");
  // background-position: top;
  // background-repeat: no-repeat;
  // overflow: auto;
  // height: 100%;

  &::-webkit-scrollbar {
    color: gray;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 12px;
    width: 10px;
    border: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #3b3b3b;
  }
}

* {
  font-family: "Segoe UI Regular", sans-serif !important;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  // * {
  //    zoom: .977;
  // }

  // Header section
  .header-container {
    margin-bottom: 0 !important;
  }
}
