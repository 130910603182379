.reminder-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  color: white;
  gap: 150px;

  .info-contaier {
    margin: 0 auto;
    text-align: center;
    max-width: 900px;
    font-size: 30px;
  }

  .btns-container {
    display: flex;
    justify-content: space-around;
    width: 600px;
    margin: 0 auto;

    .custom-next-btn .cancel .MuiButton-label::after {
      content: "\d7";
      border-right: unset;
      border-top: unset;
      height: unset;
      width: unset;
      font-size: 28px;
      transform: rotate(0deg);
      color: #ff6500;
    }
  }
  .options-container {
    margin: 0 auto;
    width: 100%;
    align-items: center;

    .item {
      max-width: 1200px;
      img {
        width: 100%;
      }
      .radio-icons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;

        .MuiRadio-root {
          color: #ff6803;
        }
        .MuiTypography-body1 {
          width: 200px;
          text-align: center;
          font-size: 28px;
        }
      }
      .active {
        color: #ff6803;
      }
    }

    .custom-next-btn {
      margin-top: 40px;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .reminder-container {
    zoom: 0.8;
  }
}
