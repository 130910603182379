.tut3__container{
    width: 100%;
    // height: 100%;
    height: 80vh;

    display: flex;
    justify-content: space-between;
    // background-color: red;
    align-items: center;
    flex-direction: column;
    color: white;
    .text{
        width: 70%;
        margin: 0;
    }
    .pdf__ {
        width: 300px;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        .pdf-content{
            text-align: center;
            cursor: pointer;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            p{
                margin: auto;

            }
        }

        img {
            // width: 50%;
            // height: 50%;
            object-fit: contain;
            cursor: pointer;
        }
        p{
            text-align: center;
        }
    }
    .btn__holder {
        display: flex;
        justify-content: space-between;
        width: 75%;
    }
    
}



@media (-webkit-device-pixel-ratio: 1.25) {
    .tut3__container {
      zoom: 0.8;
    }
  }